import React from "react";
import { SiUdemy } from "react-icons/si";
import "./course.scss";
import { YoutubeOutlined } from "@ant-design/icons";
import { Tag } from "antd";

function ThumbnailVideo({ detailCourse }) {
  console.log("detailCourse:", detailCourse);
  const parseIso860 = (iso8601Duration) => {
    if (!iso8601Duration) {
      return "";
    }
    const iso8601DurationRegex =
      /(-)?P(?:([.,\d]+)Y)?(?:([.,\d]+)M)?(?:([.,\d]+)W)?(?:([.,\d]+)D)?T(?:([.,\d]+)H)?(?:([.,\d]+)M)?(?:([.,\d]+)S)?/;
    if (!iso8601Duration) {
      return "";
    } else {
      let matches = iso8601Duration.match(iso8601DurationRegex);
      return {
        sign: matches[1] === undefined ? "+" : "-",
        years: matches[2] === undefined ? 0 : matches[2],
        months: matches[3] === undefined ? 0 : matches[3],
        weeks: matches[4] === undefined ? 0 : matches[4],
        days: matches[5] === undefined ? 0 : matches[5],
        hours: matches[6] === undefined ? 0 : matches[6],
        minutes: matches[7] === undefined ? 0 : matches[7],
        seconds: matches[8] === undefined ? 0 : matches[8],
      };
    }
  };

  const convertTotalTime = (detailCourse) => {
    let parseTime = parseIso860(detailCourse?.during);
    if (parseTime === "") {
      return "";
    }
    const hours = parseTime.hours === 0 ? "" : `${parseTime.hours}:`;
    const minutes = () => {
      if (!parseTime.minutes.length) {
        return `00`;
      } else {
        if (parseTime.minutes.length < 2) {
          return `0${parseTime.minutes}`;
        } else {
          return parseTime.minutes;
        }
      }
    };
    const seconds = () => {
      if (!parseTime.seconds.length) {
        return `00`;
      } else {
        if (parseTime.seconds.length < 2) {
          return `0${parseTime.seconds}`;
        } else {
          return parseTime.seconds;
        }
      }
    };

    const totalTime = `${hours}${minutes()}:${seconds()}`;
    return totalTime;
  };
  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor((d % 3600) / 60);
    var s = Math.floor((d % 3600) % 60);

    // var hDisplay = h < 9 ? h + "hour," : "hours";
    var hDisplay = h + "hour,";
    var mDisplay = m > 0 ? m + (m == 1 ? ":" : ":") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " " : " ") : "";
    return hDisplay + mDisplay + sDisplay;
  }

  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    const seconds = hours * 3600 - minutes * 60;

    return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${seconds}`;
  }

  function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  }

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  return (
    <div className="">
      <div className="relative">
        <a
          href={
            detailCourse?.Site?.site_name == "youtube"
              ? `https://www.youtube.com/watch?v=${detailCourse?.video_url}`
              : `https://www.udemy.com${detailCourse?.course_url}`
          }
          target="_blank"
        >
          <div className="absolute  round text-white z-[5]">
            {detailCourse?.Site?.site_name == "udemy" ? (
              <Tag
                icon={<SiUdemy />}
                color="#a435f0"
                className=" text-xs flex items-center justify-center "
              >
                <p className="text-xsv pt-[0.5px] ">Udemy</p>
              </Tag>
            ) : (
              <Tag
                icon={<YoutubeOutlined />}
                color="#cd201f"
                className=" text-xs flex items-center justify-center "
              >
                <p className="text-xsv pt-[0.5px] ">Youtube</p>
              </Tag>
            )}
          </div>
          <div className="absolute bg-black text-white bottom-[0%] p-[2px] right-[0%] w-[full] z-40">
            {/* {convertTotalTime(detailCourse)} */}
            {detailCourse?.Site?.site_name == "youtube"
              ? convertTotalTime(detailCourse)
              : convertHMS(detailCourse?.during * 60)}
          </div>
          <div className="">
            {detailCourse?.Site?.site_name == "youtube" ? (
              <img
                src={`https://i.ytimg.com/vi/${detailCourse?.img_url}/mqdefault.jpg`}
                className="w-full md:h-[180px] lg:h-[180px] xl:h-[160px]  2xl:h-[211px]"
              />
            ) : (
              <img
                src={detailCourse?.img_url}
                className="w-full md:h-[180px] lg:h-[180px] xl:h-[160px] 2xl:h-[211px]"
              />
            )}
          </div>
        </a>
      </div>
    </div>
  );
}

export default ThumbnailVideo;
