import axios from "axios";
import { BASE_URL } from "../constants/apiConstants.js";

export const coursesServices = {
  getCourseList() {
    return axios({
      url: `${BASE_URL}courses?page=1`,
      method: "GET",
    });
  },
  getAllCourse(page) {
    return axios({
      url: `${BASE_URL}courses?page=${page}`,
      // url: `https://jsonplaceholder.typicode.com/comments?_page=1&_limit=20`,
      // url: `${BASE_URL}courses?page=1`,
      method: "GET",
    });
  },
  getAllTopic() {
    return axios({
      url: `${BASE_URL}topics`,
      method: "GET",
    });
  },
  filterTopic(topicPage) {
    return axios({
      url: `${BASE_URL}courses/${topicPage.newTopic.topic_name}?page=${topicPage.pageNumber}`,
      method: "GET",
    });
  },
   
  
};
