import React from "react";

import logo from "../../assets/metacourseslogo.png";

import "./header.scss";
import MobileDrawer from "./MobileDrawer";

export default function Header({ onChange }) {
  return (
    <div className="w-full h-20 shadow-lg bg-white fixed z-50">
      <div className="flex w-full h-full">
        <div className="w-[50%] md:w-[20%] xl:w-[13%] ">
          <div className="flex items-center justify-start h-full px-10">
            <a href="/">
              <img
                src={logo}
                alt="logo"
                className="w-[200px] h-[90%] object-fill "
              />
            </a>
          </div>
        </div>
        <div className="w-[50%] md:w-[80%] xl:w-[87%] ">
          <div className="flex items-center justify-end h-full px-10">
            <MobileDrawer onChange={onChange} />
          </div>
        </div>
      </div>
    </div>
  );
}
