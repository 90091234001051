import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header/Header";
import Ribbon from "../../components/Ribbon/Ribbon";
import { filterTopic, getAllCourse } from "../../reducers/coursesSlice";
// import InfiniteScroll from "react-infinite-scroller";
import { Spin } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import CourseMain from "../../components/Course/CourseMain";
import Skeletion from "../../components/Skeletion/Skeletion";

export default function Home() {
  const [hasMore, sethasMore] = useState(true);

  const [page, setpage] = useState(0);

  const [pageCategory, setPageCategory] = useState(0);

  const [clickChangeTopic, setClickChangeTopic] = useState(false);

  const dispatch = useDispatch();

  const courseAll = useSelector((state) => state.coursesSlice.allCourse);
  console.log("courseAll:", courseAll);

  const checkEndPage = useSelector((state) => state.coursesSlice.endPage);

  const filterPrev = useSelector((state) => state.coursesSlice.filtertopic);

  const loadingSkeleton = useSelector((state) => state.coursesSlice.status);

  const topicFilterData = useSelector(
    (state) => state.coursesSlice.datafiltertopic
  );

  const [filter, setFilter] = useState({
    id: -1,
    topic_name: "viewall",
  });

  useEffect(() => {
    dispatch(getAllCourse(page + 1));
  }, []);

  useEffect(() => {
    if (checkEndPage == true) {
      sethasMore(false);
    } else {
      sethasMore(true);
    }
  }, [checkEndPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [filter]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!clickChangeTopic) {
      setpage(page + 1);
      setClickChangeTopic(false);
    } else {
      setpage(1);
      setClickChangeTopic(false);
    }
  }, [courseAll]);
  useEffect(() => {
    setPageCategory(pageCategory + 1);
  }, [topicFilterData]);

  const fetchData = () => {
    if (filter.topic_name == "viewall") {
      dispatch(getAllCourse(page));

      if (checkEndPage == true) {
        sethasMore(false);
      }
    } else {
      const pageNumber = pageCategory;
      const newTopic = filter;
      const topicPage = { newTopic, pageNumber };
      if (pageCategory > 1) {
        dispatch(filterTopic(topicPage));
      }
      if (checkEndPage == true) {
        sethasMore(false);
      }
    }
  };

  // const itemRender = 16;

  const handleTopicChange = (newTopic) => {
    // reset page
    setpage(1);
    setPageCategory(1);
    setClickChangeTopic(true);
    if (filter.topic_name != newTopic.topic_name) {
      setFilter(newTopic);
      sethasMore(true);

      if (newTopic.topic_name == "viewall") {
        dispatch(getAllCourse(1));
      } else {
        const pageNumber = 1;
        const topicPage = { newTopic, pageNumber };
        dispatch(filterTopic(topicPage));
        if (checkEndPage == true) {
          sethasMore(false);
        }
      }
    }
  };

  return (
    <div>
      <Header onChange={handleTopicChange} />
      <div className="flex w-full h-full">
        <div className="hidden md:block md:w-[20%] xl:w-[13%] shadow-xl ribbonHeight fixed bg-white top-20 z-50">
          <Ribbon onChange={handleTopicChange} />
        </div>
        <div className="hidden md:block md:w-[20%] xl:w-[13%]"></div>
        <div className="w-full md:w-[80%] xl:w-[87%] mt-20">
          <InfiniteScroll
            dataLength={filter.topic_name == "viewall" ? page : pageCategory} //This is important field to render the next data
            next={fetchData}
            hasMore={hasMore}
            loader={
              <div className="flex justify-center p-5">
                <Spin size="large" />
              </div>
            }
            endMessage={
              <div className="flex justify-center pb-5">
                <h4>YOU HAVE GO TO END PAGE</h4>
              </div>
            }
          >
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-6 gap-y-7 p-10">
              {loadingSkeleton == "loading" &&
              page <= 1 &&
              pageCategory <= 1 ? (
                <>
                  <Skeletion />
                  <Skeletion />
                  <Skeletion />
                  <Skeletion />
                  <Skeletion />
                  <Skeletion />
                  <Skeletion />
                  <Skeletion />
                  <Skeletion />
                  <Skeletion />
                  <Skeletion />
                  <Skeletion />
                </>
              ) : filter.topic_name == "viewall" ? (
                courseAll?.map((item) => (
                  <div key={item.id}>
                    <CourseMain detailCourse={item} />
                  </div>
                ))
              ) : (
                topicFilterData?.map((item) => (
                  <div key={item.id}>
                    <CourseMain detailCourse={item} />
                  </div>
                ))
              )}
            </div>
          </InfiniteScroll>
        </div>
      </div>
    </div>
  );
}
