import { BackTop } from "antd";
import Home from "./Pages/Home/Home";

function App() {
  return (
    <>
      <Home />
      <BackTop/>
    </>
  );
}

export default App;
