import moment from "moment";
import { Tooltip } from "antd";
import React from "react";
import { AiFillStar, AiFillLike } from "react-icons/ai";
import { BsFillPeopleFill } from "react-icons/bs";
import { GrFormView } from "react-icons/gr";
import { VscCommentDiscussion } from "react-icons/vsc";

function CourseDetail({ detailCourse }) {
  const convertDateYoutube = (detailCourse) => {
    let dateMonth = moment(detailCourse.last_updated).format("MMMM, YYYY");
    return dateMonth;
  };

  const convertAuthorInfor = () => {
    if (detailCourse?.site?.site_name == "youtube") {
      return `${detailCourse?.authors[0]?.name} (${detailCourse?.authors[0]?.subscribe}) subscriber`;
    }
    return `${detailCourse?.authors[0]?.name} (${detailCourse?.authors[0]?.subscribe})  student`;
  };

  const convertToInternationalCurrencySystem = (labelValue) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + "K"
      : Math.abs(Number(labelValue));
  };
  return (
    <div className="p-3">
      <div>
        <div className="font-bold text-base text-ellipsis overflow-hidden whitespace-nowrap ">
          <a
            href={
              detailCourse?.Site?.site_name == "youtube"
                ? `https://www.youtube.com/watch?v=${detailCourse?.video_url}`
                : `https://www.udemy.com${detailCourse?.course_url}`
            }
            target="_blank"
            className="hover:text-black"
          >
            <Tooltip title={detailCourse?.title}>{detailCourse?.title}</Tooltip>
          </a>
        </div>
        <div className="flex  mt-3">
          <div className="w-[70%] font-[#6a6f73]">
            <div className="font-normal  text-sm text-ellipsis text-blackTile overflow-hidden whitespace-nowrap ">
              <a
                href={
                  detailCourse?.Site?.site_name == "youtube"
                    ? `https://www.youtube.com/c/${detailCourse?.channel_name}`
                    : `https://www.udemy.com${
                        JSON.parse(detailCourse?.include)
                          ?.visible_instructors[0].url
                      }`
                }
                target="_blank"
                className="hover:text-black"
              >
                {detailCourse?.Site?.site_name == "youtube"
                  ? detailCourse?.channel_name
                  : JSON.parse(detailCourse?.include)?.visible_instructors[0]
                      .display_name}
              </a>
            </div>
            <div className="flex mt-3 text-xs">
              <div className="w-[40%] font-bold flex items-center">
                {detailCourse?.Site?.site_name == "youtube"
                  ? Number(detailCourse?.count_views).toLocaleString()
                  : Number(detailCourse?.subscribers_count).toLocaleString()}
                <div>
                  {detailCourse?.Site?.site_name == "youtube" ? (
                    <GrFormView size={16} className="ml-1" />
                  ) : (
                    <BsFillPeopleFill size={16} className="ml-1" />
                  )}
                </div>
              </div>
              <div className="w-[60%] font-bold">
                <div className="flex">
                  <div className="text-rankOrange">
                    {detailCourse?.Site?.site_name == "youtube" ? (
                      <div className="flex">
                        {Number(detailCourse?.count_like).toLocaleString()}
                        <AiFillLike className="ml-1 mt-[0.5px]" />
                      </div>
                    ) : (
                      <div className="flex justify-center items-center">
                        {detailCourse?.rating?.slice(0, 3)}/5{" "}
                        <AiFillStar className="ml-1" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center">
                  {detailCourse?.Site?.site_name == "youtube"
                    ? Number(detailCourse?.count_comment).toLocaleString()
                    : Number(detailCourse?.count_views).toLocaleString()}
                  <VscCommentDiscussion className="ml-1" />
                </div>
              </div>
            </div>
            <div className="w-[100%] text-xs">
              Uploaded date: {convertDateYoutube(detailCourse)}
            </div>
          </div>
          <div
            className={
              detailCourse?.Site?.site_name == "udemy"
                ? "ml-auto font-bold text-xl text-redPrice"
                : "ml-auto font-bold text-xl text-green"
            }
          >
            {/* JSON.parse(detailCourse?.include) */}
            {detailCourse?.Site?.site_name == "udemy"
              ? `$` + JSON.parse(detailCourse?.include)?.discount?.price.amount
              : "FREE"}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseDetail;
