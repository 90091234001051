import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTopics, setActiveTopic } from "../../reducers/coursesSlice";

import "./ribbon.scss";

export default function Ribbon({ onChange }) {
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.coursesSlice.topics);
  const activeTopic = useSelector((state) => state.coursesSlice.activeTopic);

  useEffect(() => {
    dispatch(getTopics());
  }, []);

  const upperCaseFirstWord = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  const chooseTopic = (topic) => {
    if (onChange) {
      onChange(topic);
    }
  };

  const handleActiveTopic = (index) => {
    dispatch(setActiveTopic(index));
  };

  return (
    <div id="ribbon" className="flex flex-col items-center justify-center">
      <div
        id={activeTopic === -1 ? "activeShadow" : ""}
        className="topics cursor-pointer hover:bg-hover"
        onClick={() => {
          chooseTopic({
            id: -1,
            topic_name: "viewall",
          });
          handleActiveTopic(-1);
        }}
      >
        <p>View All</p>
      </div>
      {topics?.map((topic, index) => {
        return (
          <div
            id={index === activeTopic ? "activeShadow" : ""}
            className="topics cursor-pointer hover:bg-hover"
            key={topic.id}
            onClick={() => {
              chooseTopic(topic);
              handleActiveTopic(index);
            }}
          >
            <p>{upperCaseFirstWord(topic.topic_name)}</p>
          </div>
        );
      })}
    </div>
  );
}
