import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  count : 0,
  loading : false
};

const loadingSlice = createSlice({
  name: "Loading Slice",
  initialState,
  reducers: {
    setCount: (state, action) => {
      state.count =action.payload;
    },
    removeCount: (state, action) => {
      state.count =action.payload;
    },
  },
});
export const { setCount,removeCount } = loadingSlice.actions;
export default loadingSlice.reducer
;

