import React from "react";
import CourseDetail from "./CourseDetail";
import ThumbnailVideo from "./ThumbnailVideo";

function CourseMain({ detailCourse }) {
  return (
    <div className="w-full h-full hover:shadow-xl cursor-pointer">
      <ThumbnailVideo detailCourse={detailCourse} />
      <CourseDetail detailCourse={detailCourse} />
    </div>
  );
}

export default CourseMain;
