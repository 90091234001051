import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer } from "antd";
import { FiMenu } from "react-icons/fi";
import { getTopics, setActiveTopic } from "../../reducers/coursesSlice";
export default function MobileDrawer({ onChange }) {
  const dispatch = useDispatch();
  const topics = useSelector((state) => state.coursesSlice.topics);
  const activeTopic = useSelector((state) => state.coursesSlice.activeTopic);

  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  const upperCaseFirstWord = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1);
  };

  useEffect(() => {
    dispatch(getTopics());
  }, []);

  const chooseTopic = (topic) => {
    if (onChange) {
      onChange(topic);
    }
  };

  const handleActiveTopic = (index) => {
    dispatch(setActiveTopic(index));
  };

  return (
    <div id="headerMobileButton" className="md:hidden">
      <Button type="primary" onClick={showDrawer}>
        <FiMenu className="text-xl" />
      </Button>
      <Drawer
        title="Topic"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <div className="flex flex-col items-center justify-center">
          <div
            id={activeTopic === -1 ? "activeShadow" : ""}
            className="mobileTopics cursor-pointer hover:bg-hover"
            onClick={() => {
              chooseTopic("viewall");
              handleActiveTopic(-1);
              onClose();
            }}
          >
            <p>View All</p>
          </div>
          {topics?.map((topic, index) => {
            return (
              <div
                id={index === activeTopic ? "activeShadow" : ""}
                className="mobileTopics hover:bg-hover cursor-pointer"
                key={topic.id}
                onClick={() => {
                  chooseTopic(topic);
                  handleActiveTopic(index);
                  onClose();
                }}
              >
                <p>{upperCaseFirstWord(topic.topic_name)}</p>
              </div>
            );
          })}
        </div>
      </Drawer>
    </div>
  );
}
