import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./skeletion.scss";

export default function Skeletion() {
  return (
    <div id="courseSkeletion" className="w-full h-auto ">
      <div>
        <Skeleton width={`100%`} height={215} />
      </div>
      <div>
        <Skeleton width={`100%`} height={20} />
        <Skeleton width={`100%`} height={20} />
        <Skeleton width={`100%`} height={20} />
      </div>
    </div>
  );
}
