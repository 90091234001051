import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { coursesServices } from "../services/coursesServices";

const initialState = {
  topics: null,
  allCourse: null,
  filtertopic: null,
  datafiltertopic: null,
  activeTopic: -1,
  endPage: false,
  endPageAll: false,
  status : 'loading',
};

const coursesSlice = createSlice({
  name: "coursesSlice",
  initialState,
  reducers: {
    setActiveTopic: (state, action) => {
      state.activeTopic = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTopics.fulfilled, (state, action) => {
        state.topics = action.payload;
      })
      .addCase(getTopics.rejected, (state, action) => {
        console.log(getTopics, "error");
      })
      .addCase(getAllCourse.fulfilled, (state, action) => {
        // END PAGE 
        state.status = 'succeeded'
        if(action.payload.data.length == 0 ){
            state.endPage = true;
        }
        else{
          if (action.payload.page != 1 ) {
            state.allCourse = [...state.allCourse, ...action.payload.data];
          } else {
            state.allCourse = action.payload.data;
          }
          state.endPage = false;
        }
      })
      .addCase(getAllCourse.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(getAllCourse.rejected, (state, action) => {
        console.log(getAllCourse, "error");
      })
      .addCase(filterTopic.fulfilled, (state, action) => {
        action.payload.checkNextPageEmpty
          ? (state.endPage = true)
          : (state.endPage = false);
        state.status = 'succeeded'
        if (state.filtertopic === action?.payload.topicPage.newTopic.topic_name ) {

          state.datafiltertopic = [
            ...state.datafiltertopic,
            ...action.payload.data,
          ];
        } else {
          state.datafiltertopic = action?.payload.data;
          state.filtertopic = action?.payload.topicPage.newTopic.topic_name;
        }
      })
      .addCase(filterTopic.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(filterTopic.rejected, (state, action) => {
        console.log(filterTopic, "error");
      });
  },
});

export const { setActiveTopic } = coursesSlice.actions;

export default coursesSlice.reducer;

export const getTopics = createAsyncThunk("getTopics", async () => {
  const response = await coursesServices.getAllTopic();
  return response.data;
});
export const getAllCourse = createAsyncThunk("getAllCourse", async (page) => {
  const response = await coursesServices.getAllCourse(page);
  const addPage = { data: [...response.data], page };
  return addPage;
});
export const filterTopic = createAsyncThunk(
  "filterTopic",
  async (topicPage) => {
    const pageNumberCategory = topicPage.pageNumber + 1;
    const nextPage = { ...topicPage, pageNumberCategory };

    const response = await coursesServices.filterTopic(topicPage);

    const checkNextPageEmpty =
    response?.data?.length < 16 ? true : false;

    const addTopic = { data: [...response.data], topicPage,checkNextPageEmpty };
    return addTopic;
  }
);
